var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container--fluid",attrs:{"id":"report_member_pay"}},[_c('v-card',[_c('v-card-title',[_vm._v(" รายงานข้อมูลสรุป(ตำบล) "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('v-select',{staticClass:"pt-9",attrs:{"items":this.$store.getters.getlistProvince || [],"item-value":"ProID","item-text":"ProName","label":"จังหวัด","required":""},model:{value:(_vm.dataFrm.ProID),callback:function ($$v) {_vm.$set(_vm.dataFrm, "ProID", $$v)},expression:"dataFrm.ProID"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('v-select',{staticClass:"pt-9",attrs:{"items":this.$store.getters.getlistDistrict({
              ProID: this.dataFrm.ProID,
            }) || [],"item-text":"DisName","item-value":"DisID","label":"อำเภอ","required":""},model:{value:(_vm.dataFrm.DisID),callback:function ($$v) {_vm.$set(_vm.dataFrm, "DisID", $$v)},expression:"dataFrm.DisID"}})],1),_c('v-select',{staticClass:"pt-9",attrs:{"items":this.$store.getters.getlistPeriod,"label":"งวดที่","item-text":"period_number","item-value":"period_number"},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.loadData}},[_vm._v("แสดง")]),_c('v-btn',{attrs:{"color":"warning","href":'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/report_member_dead.php?period='+_vm.period}},[_vm._v("เอกสารรายงานผู้เสียชีวิต")]),_c('v-btn',{attrs:{"color":"gray"},on:{"click":_vm.onExport}},[_vm._v("Export")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cogs")]),_vm._v(" เอกสาร "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","target":"_blank","href":'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report6.php?period=' +
                _vm.period +
                '&IDAddAll=' +
                item.mem_province +
                '|' +
                item.mem_district +
                '|' +
                item.mem_locality}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-pdf-box")]),_vm._v(" ใบสรุปยอดรายละเอียดตามหมู่บ้าน")],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }