<template>
  <div id="report_member_pay" class="container--fluid">
    <v-card>
      <v-card-title>
        รายงานข้อมูลสรุป(ตำบล)
        <v-spacer></v-spacer>
        <v-col cols="12" md="6" lg="2">
          <v-select
            class="pt-9"
            v-model="dataFrm.ProID"
            :items="this.$store.getters.getlistProvince || []"
            item-value="ProID"
            item-text="ProName"
            label="จังหวัด"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <v-select
            class="pt-9"
            v-model="dataFrm.DisID"
            :items="
              this.$store.getters.getlistDistrict({
                ProID: this.dataFrm.ProID,
              }) || []
            "
            item-text="DisName"
            item-value="DisID"
            label="อำเภอ"
            required
          ></v-select>
        </v-col>
        <v-select
          class="pt-9"
          :items="this.$store.getters.getlistPeriod"
          label="งวดที่"
          item-text="period_number"
          item-value="period_number"
          v-model="period"
        ></v-select>
        <v-btn color="success" @click="loadData">แสดง</v-btn>
        <!-- <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-btn color="warning" :href="'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/report_member_dead.php?period='+period">เอกสารรายงานผู้เสียชีวิต</v-btn>
        <v-btn color="gray" @click="onExport">Export</v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="desserts" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"
                ><v-icon>mdi-cogs</v-icon> เอกสาร
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                target="_blank"
                :href="
                  'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report6.php?period=' +
                  period +
                  '&IDAddAll=' +
                  item.mem_province +
                  '|' +
                  item.mem_district +
                  '|' +
                  item.mem_locality
                "
              >
                <v-list-item-title
                  ><v-icon>mdi-pdf-box</v-icon>
                  ใบสรุปยอดรายละเอียดตามหมู่บ้าน</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "report_member_pay",
  data() {
    return {
      dataFrm: {
        ProID: "50",
        DisID: "10",
      },
      desserts: [],
      search: "",
      period: "",
      headers: [
        { text: "จังหวัด", value: "ProName" },
        { text: "อำเภอ", value: "DisName" },
        { text: "ตำบล", value: "LocName" },
        { text: "งวดที่", value: "sav_period" },
        { text: "จำนวนสมาชิกทั้งหมด", value: "count_member" },
        { text: "ยอดเรียกเก็บ", value: "ssav_money" },
        { text: "จำนวนสมาชิกที่ชำระ", value: "count_member_pay" },
        { text: "ยอดชำระ", value: "ssav_money_member_pay" },
        { text: "จำนวนสมาชิกไม่ได้ชำระ", value: "count_member_not_pay" },
        { text: "ยอดค้างชำระ", value: "ssav_money_not_pay" },
        { text: "เอกสาร", value: "actions" },
      ],
    };
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.$store.getters.getlistMemberReport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "report_member_pay.xlsx");
    },
    showReport(item, type) {
      item;
      type;
    },
    loadData() {
      // console.log("test");
      window.$.post(
        this.$store.state.pathAPI + "report/member_pay_locality",
        {
          period: this.period,
          ProID: this.dataFrm.ProID,
          DisID: this.dataFrm.DisID,
        },
        (param) => {
          this.desserts = param;
        },
        "json"
      );
    },
  },
  async mounted() {
    this.$store.dispatch("getlistPeriod");
    // await this.loadData();
  },

  filters: {
    ConvDateToDMY_TH: (dateTxt) => {
      let dtArr = dateTxt.split("-");
      return dtArr[2] + "/" + dtArr[1] + "/" + (parseInt(dtArr[0]) + 543).toString();
    },
  },
};
</script>

<style></style>
